import React,{useEffect} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Testimonials from '../component/Testimonials/Testimonials'
import EventBox from '../component/EventBox/EventBox'
import UpcomingEvent from '../component/UpcomingEvent/UpcomingEvent'
import { Helmet } from "react-helmet";

const eventData = [
  {
    img:"/images/creative/matrimonial-content-img.png",
    subHeading:"BEST MATRIMONIAL EVENT",
    title:"FIND YOUR PERFECT MATCH AT MUSLIM MATRIMONIAL",
    content:"He made you in pairs, and we can help you find them, connect with them, and create an everlasting bond. Participants are expected to register their interest in the event and show up with the sole objective of getting married along with their families. Meetings with people of the opposing gender are set up using profile cards and brief, topical group introductions.",
    btnRoute:"matrimonial-festival",
    borderColor:"yellow",
    listPoint:["Meet your potential life partner","A cultural environment and halal environment","A safe and welcoming space","Participating in enriching activities","Experienced and personalized matchmaking services","Take care to protect your privacy","Commitment to values"]
  },
  {
    reverse:true,
    img:"/images/creative/Muslim-Fest-content-img.png",
    subHeading:"TORONTO MUSLIM FEST",
    title:"TIME TO HAVE FUN AT MUSLIMFEST",
    content:"Get ready for a vibrant celebration of culture and community at the Toronto Muslim Fest. This event promises a day filled with entertainment, cultural performances, delicious food, and opportunities to connect with fellow members of the Muslim community in Toronto. In addition to promoting the arts and entertainment, Muslim Fest has made a significant contribution to highlighting the diversity of Islamic culture and identity. The convergence of religion and culture is honored at the Muslim Fest.",
    btnRoute:"muslim-festival",
    borderColor:"blue",
    listPoint:["Enjoy live performances from new artists","Shop around the stalls","Buy your favorite jewelry and clothes","Enjoy cultural food","Get your hands done with mehendi","Enjoy group activities with your community","Celebrate diversity"]
  },
  {
    img:"/images/creative/azadi-Festival-content-img.png",
    subHeading:"BEST AZADI FESTIVAL",
    title:"AZADI FESTIVAL CELEBRATION LIKE NO OTHER",
    content:"Don't miss the extraordinary celebration of freedom. Immerse yourself in cultural richness and joy, with events meticulously crafted for unity. From vibrant displays of tradition to modern expressions of freedom, this festival captures the essence of Azadi in every detail. Join us in commemorating this momentous occasion with a fusion of festivities that truly make it the best Azadi Festival yet!",
    btnRoute:"azadi-festival",
    borderColor:"pink",
    listPoint:["Diverse Cultural Show","Stalls for Shopping","Culinary Delights from Traditions","Artisan Markets for Authentic Crafts","Engaging Activities for All Ages","Inspirational Speaker Sessions","Unforgettable Community Bonds"]
  },
  {
    reverse:true,
    img:"/images/creative/Eid-Fest-content-img.png",
    subHeading:"BEST EID FESTIVAL",
    title:"Sipra’s Timeless Eid festival celebration",
    content:"Experience the pinnacle of celebration with SIPRA Promotions' Best Eid Festival. Immerse yourself in a vibrant blend of tradition and innovation, as we curate an unforgettable event filled with joy, cultural richness, and inclusive festivities. From captivating performances to mouth-watering cuisine, join us in creating cherished memories and fostering unity. SIPRA's commitment ensures the Best Eid Festival is an unparalleled celebration for everyone.",
    btnRoute:"eid-festival",
    borderColor:"yellow",
    listPoint:["Traditional clothes","Mehndi stalls","Video and photography","Live performances","Delicious Halal Food","Bazaar & Shopping","Kid's Activities"]
  },
  {
    img:"/images/creative/Food-Fest-content-img.png",
    subHeading:"BEST FOOD FESTIVALS",
    title:"Beyond Taste Buds: Flavorful Food Festival",
    content:"SIPRA Promotions elevates cultural experiences through food festivals, blending culinary delights with vibrant traditions. From traditional favorites to modern interpretations, our events bring together a tapestry of tastes, aromas, and cultural expressions. Join us in savoring the essence of Pakistani culinary traditions, fostering community connections, and creating unforgettable moments that celebrate the joy of food and shared experiences.",
    btnRoute:"food-festival",
    borderColor:"blue",
    listPoint:["Cuisines and Delicacies" ,"Delicious Halal Food","Bazaar & Shopping","Kids' Activities","Video and photography","Live performances"]
  }
]

function EventMain() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const specialComponentId = urlParams.get('id');
    if (specialComponentId == "bookticket") {
      
      const specialComponent = document.getElementById("ticketbook");
      if (specialComponent) {
        specialComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
        <Helmet>
          <title>Vibrant Muslim Festivals in Toronto | SIPRA Promotions</title>
          <meta name="description" content='Join us for enriching cultural experiences at the Best Muslim Matrimonial, Toronto Muslim Fest, Azadi Festival, and Eid Fest. Discover joy, tradition, and unity.'/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
        <HeroBanner 
        subHeading=""
        Heading="OUR "
        spanHeading='EVENTS'
        nextLineSpanHeading=""
        Paragraph="We organize events that go beyond the ordinary, providing a platform for individuals to come together, share their stories, and build lasting connections."
        btnText=""
        bgImg="/images/creative/event-main-bg.webp"
        btn={false}
        bgEffectOverlay={true}
        />
        <div className='my-5'></div>

        {
          eventData.map((ele)=>{
            return (<EventBox 
              reverse={ele.reverse}
              img={ele.img}
              subHeading={ele.subHeading}
              title={ele.title}
              content={ele.content}
              btnRoute={ele.btnRoute}
              borderColor={ele.borderColor}
              listPoint={ele.listPoint}
            />)
          })
        }

        <UpcomingEvent/>

        <Testimonials/>
    </>
  )
}

export default EventMain