

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
}



function CardBox(props) {
    const para = truncateText(props.content,92)
  return (
    <div className="card-box">
        <img src={props.cardImg} alt="news-thumbnail" />
        <div className="card-text-content">
          <h5 className="font-bebas">{props.title}</h5>
          <p>{para}</p>
          <a onClick={()=>{props.redirectionFunction(props.anchor)}} className="read-more-anchor">Read More</a>
        </div>
    </div>
  )
}

export default CardBox