import React from "react";
import HeroBanner from "../component/Common Banner Component/HeroBanner";
import Sponsor from "../component/Sponsor/Sponsor";
import VendorPackages from "../component/VendorPackages/VendorPackages";
import VendorFloorPlan from "../component/VendorFloorPlan/VendorFloorPlan";
import {Helmet } from "react-helmet"

function vendorBooking() {
  return (
    <>
    <Helmet>
          <title>Vendor Booking for Floor Plan Eid Festival 2023 | The Estate Banquet Hall</title>
          <meta name="description" content='Book stalls for the Floor Plan Eid Festival 2023 at The Estate Banquet Hall, Scarborough. Choose from regular, prime, or occupied stalls.'/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
      <HeroBanner
        subHeading=""
        Heading="VENDOR "
        spanHeading="BOOKING"
        Paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
        btnText=""
        bgImg="/images/creative/vendor-bg.jpg"
        btn={false}
      />
      <VendorPackages
        eventName={"MATRIMONIAL EVENT"}
        eventDate={"08 -12 AUGUST"}
        eventLocation={"99th, Qelilink Street"}
        eventTime={"09:30AM - 10:30AM"}
      />
      <VendorFloorPlan />
      <Sponsor />
    </>
  );
}

export default vendorBooking;