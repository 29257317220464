import React from 'react'
import "./EventCountDown.css"

function EventCountDown({
    countDown,
    showHeading=true
}) {
  return (
    <div className={`countdown-container text-center ${showHeading ? "standard-padding-space" : "py-3"}`}>
        {showHeading && <h4 className='color-blue title'>COUNTDOWN</h4>}

        <div className='count d-flex align-items-center gap-sm-5 gap-3 mt-4'>
            <div className='days d-flex flex-column align-items-center'>
                <h2 className='strokeText'>{countDown.days}</h2>
                <h6 className='color-yellow'>DAYS</h6>
            </div>
            <div className='hours d-flex flex-column align-items-center'>
                <h2 className='strokeText'>{countDown.hours}</h2>
                <h6 className='color-yellow'>HOURS</h6>
            </div>
            <div className='min d-flex flex-column align-items-center'>
                <h2 className='strokeText'>{countDown.minutes}</h2>
                <h6 className='color-yellow'>MIN</h6>
            </div>
            <div className='sec d-flex flex-column align-items-center'>
                <h2 className='strokeText'>{countDown.seconds}</h2>
                <h6 className='color-yellow'>SEC</h6>
            </div>

        </div>
    </div>
  )
}

export default EventCountDown