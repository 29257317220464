import "./Footer.css"
import React, { useState } from "react";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import axios from "axios";
import {Spinner} from "react-bootstrap"

function Footer() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-newsletter", {
        email: email,
        formType: "Newsletter",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Newsletter Subscribed Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
    
  return (
    <div className="footer-container pt-5">

        {/* newsletter Upper section */}
        <div className="upper-newsletter-container container">

            <div className="row d-flex align-items-center gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            <img  src="/images/creative/logo.png" alt="" onClick={()=>{navigate("/home")}} style={{cursor:"pointer"}}/>
                        </div>
                        <div className="text-content">
                            <p>The premier event organizing company for Pakistani-Canadian Muslims on a mission to unite people across Canada.</p>
                        </div>
                    <div className="social-link">
                        <a href="https://www.facebook.com/siprapromotions/"><img src="images/icons/facebook-icon.svg" alt="" /></a>
                        <a href="https://www.instagram.com/siprapromotions/"><img src="images/icons/instagram-icon.svg" alt="" /></a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="page-links d-flex flex-column align-items-center text-center justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-2">
                            <Link to={"/home"}>
                                Home
                            </Link>
                            <Link to={"/about"}>
                                About Us
                            </Link>
                            <Link to={"/news"}>
                                News
                            </Link>
                            <Link to={"/event"}>
                                Events
                            </Link>
                            <Link to={"/contact"}>
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="form-container">
                        <h5 >Stay Updated About Events</h5>
                        <p>Don’t miss out the latest updates about exciting upcoming events. Subscribe to the newsletter now</p>
                        <form className="newsletter-form mt-4" onSubmit={formSubmitHandler}>
                        
                            <input 
                             required
                             type="email"
                             value={email}
                             maxLength="36"
                             onChange={(e) => setEmail(e.target.value)}
                             placeholder="Enter your email" 
                            />
                            <button type="submit">
                                Subscribe
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" }}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            
           
        </div>

        {/* div for border lining */}
        <div className="line-seprator"></div>

        {/* Lower sections */}

        <div className="lower-container ">
            <div className="container d-flex flex-column flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
                <p className="mb-0">Copyright © 2024 Toronto Muslim Fest. All rights reserved.</p>
            </div>
        </div>
        
    </div>
  )
}

export default Footer