export const showPopupMessage = (message, type='fail', duration = 3000) => {
    const popup = document.createElement('div');
    popup.className = `${ type != 'success' ? 'popup-message-err' : 'popup-message-success'}`;
    popup.textContent = message;

    document.body.appendChild(popup);
 
    setTimeout(() => {
        document.body.removeChild(popup);
    }, duration);
}

export const getLoggedInUserDiscounts = (type, username, email, allBookedSlots, date) => {
    const userRecords = allBookedSlots.filter(item => item.username === username && item.email === email && item.type === type);

    if (userRecords.length === 0) {
        return 0;
    } else {
        const dates = userRecords.map(record => new Date(record.date).getTime());
        const uniqueDates = [...new Set(dates)];
        
        if (!uniqueDates.includes(new Date(date).getTime())) { 
            return uniqueDates.length;
        } else {
            return 0;
        }
    }
};

export const generateRandomPassword = () => {
    const min = 10000; 
    const max = 99999; 

    return Math.floor(Math.random() * (max - min + 1)) + min;
};