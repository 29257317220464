import React from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';

// function TextContent() {
//   return (
//     <div className='text-content-section standard-padding-space d-flex flex-column justify-content-center align-items-center gap-3'>
//         <h3 className='body-heading'>ABOUT <span className='color-yellow'>SIPRA</span></h3>
//         <p className='body-paragraph w-75 mx-auto'>As the premier event organizing company for Pakistani-Canadian Muslims, we take immense pride in our mission to bring people together across Canada, transcending boundaries of race, gender, caste, and culture. At SIPRA, our mission is simple yet powerful. We strive to unite people from all walks of life, fostering a sense of belonging that goes beyond any differences. We believe in creating a community that celebrates diversity, embracing the richness that each individual brings to the tapestry of Canadian society. Our commitment to the community is resolute. We prioritize inclusivity, ensuring that our events welcome people from all backgrounds, fostering a sense of unity and understanding. We actively engage in initiatives that uplift and support less-advantaged Canadian Muslims, making a positive impact on their lives. From meticulous event planning to seamless execution, we bring passion and excellence to every aspect of our work. Your celebrations deserve nothing less than perfection. Become the part of SIPRA events in creating moments that matter. Join us in our mission to celebrate life, foster unity, and embrace the beautiful diversity that makes Canada truly special.</p>
//         <button className='genral-btn mt-2'>ABOUT US</button>
//     </div>
//   )
// }

function TextContent({
  heading,
  spanHeading="",
  subHeading="",
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnText,
  btnRef,
  shiftRow=false,
  img,
  reviewContent= false,
  review,
  reviewUser,
  userImg,
  mobileTextCenter=true
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div className='text-content-section standard-padding-space'>
      <div className='container-space'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid' style={{width:"90%",borderRadius:"15px"}}/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `}>
              <p className='sub-heading mb-0'>{subHeading}</p>
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                <h3 className='body-heading'>{heading} <span className='color-yellow'>{spanHeading}</span></h3>
                <p className='sub-heading mb-2'>{postSubHeading}</p>
                <div className=''>{paragraphs}</div>
                {/* <h3 className='body-heading mt-4'>{postHeading}</h3>
                <p className='body-paragraph my-1 mb-3'>{postContent}</p> */}
                {btn && <button className='genral-btn mt-2' onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
              </div>
              {
                reviewContent &&
                <>
                  <div className='line-seprator my-2 mt-4'></div>
                  <div className='d-flex align-items-start gap-3 pt-4'>
                    <img src={userImg}/>
                    <div className='my-3'>
                      <p className='body-paragraph mb-1'>{review}</p>
                      <p className='body-paragraph mb-0'>{reviewUser}</p>
                    </div>
                  </div>
                  <button className='text-btn mt-3'>ABOUT US</button>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent