import React, { useState,useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const navigate = useNavigate()

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
          if (!event.target.closest('.navigation-dropdown')) {
            setIsDropdownOpenDesk(false);
          }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);

  const onClickEvents = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/event");
        clickCountRef.current = 0;
      }
  };

  return (
    <div className={`header`}>
      <div className="container">
      <div className="header-wrapper pt-3 w-100">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-md-0 px-2">
            <div className="nav-elements d-flex justify-content-between align-items-center ">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/creative/logo.png" className="nav-logo" onClick={()=>{navigate("/home")}} />
              <nav>
                <ul
                  className="nav-list d-lg-flex gap-5 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/home"} className="nav-links">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="nav-links">
                      ABOUT US
                    </Link>
                  </li>
                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseOver={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center">
                          <Link to={"/event"} className="nav-links " onClick={()=>{setIsDropdownOpenDesk(false)}}>EVENTS</Link>
                          <img src='/images/icons/drop-icon.svg' className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none'}`} onMouseOver={()=>{setIsDropdownOpenDesk(true)}} onMouseOut={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/matrimonial-festival"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">MATRIMONIAL EVENT</p>
                        </Link>
                        <Link to={"/muslim-festival"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">MUSLIM FEST</p>
                        </Link>
                        <Link to={"/azadi-festival"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">AZADI FESTIVAL</p>
                        </Link>
                        <Link to={"/eid-festival"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">EID FESTIVAL</p>
                        </Link>
                        <Link to={"/food-festival"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">FOOD FESTIVAL</p>
                        </Link>
                      </div>
                    </li>
                    </div>
                    
                  <li>
                    <Link to={"/vendor"} className="nav-links">
                        BOOK A STALL
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"} className="nav-links">
                      CONTACT US
                    </Link>
                  </li>
                  
                  <li>
                    <Link to={"/news"} className="nav-links">
                        NEWS
                    </Link>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src={`${menuState ?"/images/icons/resp-menu-cross.png" :"/images/icons/hamburger-menu.svg"}`}
                  className="img-fluid menu-btn"
                  style={{cursor:"pointer"}}
                />
              </label>

              {/* <div className="nav-buttton ml-5 d-lg-flex d-none gap-4 extras-btns">
                <button className="blue-btn">
                  Get Consultation
                </button>
              </div> */}
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100" >
            <ul
              className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4 py-3"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/home"} className="nav-links">
                  HOME
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="nav-links">
                  ABOUT US
                </Link>
              </li>
              <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickEvents} >
                      <a className="nav-links" >EVENTS</a>
                      <img src='/images/icons/drop-icon-dark.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                        <Link to={"/matrimonial-festival"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                          <p className="mb-0">MATRIMONIAL EVENT</p>
                        </Link>
                        <Link to={"/muslim-festival"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                          <p className="mb-0">MUSLIM FEST</p>
                        </Link>
                        <Link to={"/azadi-festival"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                          <p className="mb-0">AZADI FESTIVAL</p>
                        </Link>
                        <Link to={"/eid-festival"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                          <p className="mb-0">EID FESTIVAL</p>
                        </Link>
                        <Link to={"/food-festival"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                          <p className="mb-0">FOOD FESTIVAL</p>
                        </Link>
                  </div>
                </div>
                </li>
              <li>
                <Link to={"/vendor"} className="nav-links">
                  BOOK A STALL
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className="nav-links">
                  CONTACT US
                </Link>
              </li>
              <li>
                <Link to={"/news"} className="nav-links">
                  NEWS
                </Link>
              </li>
              <li>
                {/* <div className="nav-buttton mt-4 flex-wrap d-flex gap-4 extras-btns">
                  <button className="blue-btn">
                    Get Consultation
                  </button>
                </div> */}
              </li>
            </ul>
          </nav>
        </nav>
      </div>
      </div>
    </div>
  );
};

export default Header; 