
function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card">
        <div className="testimonial-card-info">
          <div className="swiper-button-prev" onClick={props.handlePrevButtonClick}>
              <img src="/images/icons/testimonial-left.svg" className="arrow-img"/>
          </div>
          <div className="profile-info px-2">
            
              <img src={props.clientImg} alt="" />
              <p className="review-text w-75">{props.clientReview}</p>
              <h5 className="name">{props.clientName}</h5>
          </div>
          <div className="swiper-button-next" onClick={props.handleNextButtonClick}>
              <img src="/images/icons/testimonial-right.svg" className="arrow-img"/>
          </div>
        </div>
    </div>
  )
}

export default TestimonialCard