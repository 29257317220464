import React from 'react'
import "./EventTab.css"
import { useNavigate } from 'react-router-dom'


function EventTab({
    Heading="",
    spanHeading="",
    Paragraph=""
  }) {

    const eventData = [
      {
        title:"MATRIMONAL FESTIVAL",
        link:"matrimonial-festival"
      },
      {
        title:"TORONTO MUSLIM FEST",
        link:"muslim-festival"
      },
      {
        title:"AZADI FESTIVAL",
        link:"azadi-festival"
      },
      {
        title:"EID FESTIVAL",
        link:"eid-festival"
      },
      {
        title:"FOOD FESTIVALS",
        link:"food-festival"
      }
    ]

    const navigate =useNavigate()

  return (
    <div className='event-container standard-padding-space'>
        <div className="text-content d-flex flex-column justify-content-center align-items-center">
            <h1 className="body-heading">
              {Heading}
            </h1>
            <h1 className='body-heading'><span className="color-pink">{spanHeading}</span></h1>
            <p className="body-paragraph w-50">{Paragraph}</p>
            <div className='event-list mt-5'>
                {
                    eventData.map((ele)=>{
                        return(
                            <div className='event-box mb-4 font-bebas'>
                                <h3 onClick={()=>{navigate(`/${ele.link}`)}}>{ele.title}</h3>
                                <div className='line-seprater mt-3'></div>
                            </div>)
                    })
                }
                

            </div>
        </div>
        
    </div>
  )
}

export default EventTab