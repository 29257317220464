import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./EventBox.css"

function EventBox({
    borderColor,
    reverse=false,
    btnText="READ MORE",
    btnRoute="",
    img,
    subHeading,
    title,
    content,
    listPoint=[],
    }){
        const naviInner = useNavigate("",{preventScrollReset:false})
  return (
    <div className='event-box-container standard-padding-space '>
        <div className='container'>
            <div className={`row d-flex align-items-center gy-3 ${reverse && "flex-row-reverse"}`}>
                <div className='col-lg-6'>
                    <div className={`img-container${reverse?"-reverse":""} d-flex align-items-center justify-content-center ${reverse ? "justify-content-lg-end" : "justify-content-lg-start"}`} >
                        {/* <div className={`img-background${reverse?"-reverse":""} d-lg-block d-none `} style={borderColor === "pink"?{borderColor:"#CB317F"}:borderColor ==="yellow"?{borderColor:"#F1B229"}:{borderColor:"#02A1A9"}}></div> */}
                        <img src={img} className='img-fluid content-img my-auto '/>
                    </div>
                </div>
                <div className='col-lg-6 mt-4 mt-lg-0' >
                    <div className='text-content d-lg-block d-flex flex-column text-lg-start text-center align-items-center justify-content-center'>
                        <h5 className='body-sub-heading color-pink'>{subHeading}</h5>
                        <h3 className='body-heading py-1'>{title}</h3>
                        <p className='body-para py-1'>{content}</p>
                        {/* <div className='container'> */}
                            <div className='row gy-3 py-2 '>
                                {
                                    listPoint.map(ele =>{
                                        return(
                                            <div className='d-flex align-items-start col-lg-6 icon-list'>
                                                <img src='/images/icons/list-bullet.png' className='list-bullet '/>
                                                <p className='list-para mb-0'>{ele}</p>
                                            </div>)
                                    })
                                }

                            </div>
                            <button className='genral-btn-plain mt-4' onClick={()=>{naviInner(`/${btnRoute}`)}}>{btnText}</button>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventBox