import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import TestimonialCard from "./TestimonialCard";

function Testimonials() {
  const blogData = [
    {clientName:"John Doe",clientImg:"/images/creative/testimonial-img.png",clientReview:"The events were thoughtfully organized, embracing both tradition and innovation. The atmosphere was vibrant, and the sense of community was palpable. SIPRA's commitment to making celebrations meaningful is truly commendable. Looking forward to more!"},
    {clientName:"Amelia	Edmunds",clientImg:"/images/creative/testimonial-img.png",clientReview:"My kids enjoyed fun-filled activities and programs that deepened their connection to our culture and faith. SIPRA's impact on the younger generation is invaluable. Thank you for nurturing our community's future leaders."},
  ]

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="container">
    <div className="row d-flex align-items-center">
      <div className="col-md-12 text-center ">
        <h3 className="color-pink">OUR TESTIMONIAL</h3>
        <h3 className="color-yellow mb-5 body-heading">Here is what the attendees have to say</h3>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list"
            id="articles-cards-slider"
            >
              {
                blogData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} handleNextButtonClick={handleNextButtonClick} handlePrevButtonClick={handlePrevButtonClick}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials