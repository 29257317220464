import React, { useEffect, useState } from "react"; 
import './VendorSeatsSlots.css'; 
import Loader from "../Helper/Loader";
import LoginUserPopup from "./LoginUserPopup";

const PrimeSeats= [7, 47, 40, 39, 32, 31, 24, 23];
const VendorStallsDates = [
    {
        id: '2024-04-6',
        label: '6 April 2024'
    },
    {
        id: '2024-04-7',
        label: '7 April 2024'
    },
    {
        id: '2024-04-8',
        label: '8 April 2024'
    },
    {
        id: '2024-04-9',
        label: '9 April 2024'
    }  
];
 
const VendorSeatsSlots = () => {
    const [openLoginPopup, setOpenLoginPopup] = useState({ 
        primeSeats: [],
        regularSeats: [], 
        allBookedSlots: [],
        fetchRecordsFromDates: VendorStallsDates[0].id,
        isOpen: false
    });
    const [currentValue, setCurrentValue] = useState(VendorStallsDates[0].label);
    const [fetchRecordsFromDates, setFetchRecordsFromDates] = useState(VendorStallsDates[0].id);
    const [locMenu, setLocMenu] = useState(false);

    const [allBookedSlots, setAllBookedSlots] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [occupiedSeats, setOccupiedSeats] = useState([]);

    const [loadingSlots, setLoadingSlots] = useState(true);
 
    useEffect(() => {  getAllBookedSlots() }, []);
    useEffect(() => { filterBookedSlots() }, [fetchRecordsFromDates]);

    const getDateFilteredArray = (array) => {
        return array.filter(item => item.date === fetchRecordsFromDates);
    };

    const getAllBookedSlots = async () => {  
        if(!fetchRecordsFromDates) {
            setLoadingSlots(false);
            return;
        };
 
        setLoadingSlots(true);

        try {  
            const response = await fetch('/get-booked-slots');
            const data = await response.json();
    
            if (response.ok) {  
                setLoadingSlots(false);

                setAllBookedSlots(data.data);
                filterBookedSlots(data.data);
            }
        } catch (error) { 
            setLoadingSlots(false); 
        }
    };

    const filterBookedSlots = (array=allBookedSlots) => {
        const dateWiseArray = getDateFilteredArray(array);
        const allSlotsBooked = dateWiseArray.map(item => item.no_slots).flat();

        setOccupiedSeats(allSlotsBooked);
    };

    const handleSeatClick = (seatNumber) => {
        const isSeatSelected = selectedSeats.includes(seatNumber);
        const occupiedSeat = occupiedSeats.includes(seatNumber); 

        if(seatNumber == 'Door' || seatNumber == 'Stage' || seatNumber == '' || occupiedSeat) return; 
          
        if (isSeatSelected) { 
            setSelectedSeats((prevSelectedSeats) =>
                prevSelectedSeats.filter((seat) => seat !== seatNumber)
            );
        } else { 
            setSelectedSeats((prevSelectedSeats) => [...prevSelectedSeats, seatNumber]);
        }
    };
    
    const renderSeats = () => {
        const rows = [
            { seats: [11, 10, 'Stage', 9, 8, 7] },
            { seats: [12, '', 44, 45, 46, 47, '', 6] },
            { seats: [13, '', 43, 42, 41, 40, '', 5] },
            { seats: ['', '', '', '', '', '', '', 'Door'] },
            { seats: ['', '', '', '', '', '', '', ''] },
            { seats: [14, '', 36, 37, 38, 39, '', 4] },
            { seats: [15, '', 35, 34, 33, 32, '', 3] },
            { seats: ['', '', '', '', '', '', '', 'Door'] },
            { seats: ['', '', '', '', '', '', '', ''] },
            { seats: [16, '', 28, 29, 30, 31, '', 2] },
            { seats: [17, '', 27, 26, 25, 24, '', 1] },
            { seats: [18, 19, 20, 21, 22, 23, '', ''] }, 
        ];

        return rows.map((row, rowIndex) => (
            <div key={rowIndex} className={`row`}>
                {row.seats.map((seat, seatIndex) => (
                    <div
                        key={seatIndex}
                        className={`seat ${getSeatClass(seat)}`}
                        onClick={() => handleSeatClick(seat)}
                    >
                        {seat === '' ? 'Street' : seat}
                    </div>
                ))}
            </div>
        ));
    };

    const getSeatClass = (seat) => {
        const isPrimeSeat = PrimeSeats.includes(seat);
        const isSelected = selectedSeats.includes(seat);
        const occupiedSeat = occupiedSeats.includes(seat);
      
        if (isPrimeSeat) {
            return `seat ${isSelected ? 'selected' : occupiedSeat ? 'occupied' : 'prime'}`;
        } else if (occupiedSeat) {
            return 'occupied';
        } else if (seat === 'Door' || seat === 'Stage') {
            return 'stage';
        } else if (seat === '') {
            return 'no-place';
        } else {
            return `seat ${isSelected ? 'selected' : ''}`;
        }
    };

    const BookSlots = () => {
        if(selectedSeats.length === 0) return;

        const primeSeats = [];
        const regularSeats = [];

        selectedSeats?.forEach(seat => {
            if (PrimeSeats.includes(seat)) primeSeats.push(seat);  
            else regularSeats.push(seat); 
        });
  
        setOpenLoginPopup({
            isOpen: true,
            primeSeats: primeSeats,
            regularSeats: regularSeats,
            allBookedSlots: allBookedSlots,
            fetchRecordsFromDates: fetchRecordsFromDates
        });
    };

    const getCurrentDropdownData = (obj) => {
        if(!obj) return; 

        setFetchRecordsFromDates(obj.id);
        setCurrentValue(obj.label);
        setLocMenu(false)
    };

    const FilteringDropdownVendors = () => { 
        const toogleMenu = () => { setLocMenu((prev) => { return !prev }) };
        return (
            <div className="event-info-container my-5 ">
                <div className="row d-flex justify-content-end">
                    <div className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-4 align-items-center border-right">
                        <img src="/images/icons/location-icon.svg" />
                        <p className="body-paragraph mb-0">{"THE ESTATE BANQUET HALL, 430 NUGGET AVE, SCARBORUGH, M1S 4A4"}</p>
                    </div>
                    <div className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-4 align-items-center border-right">
                        <img src="/images/icons/alarm-icon.svg" />
                        <p className="body-paragraph mb-0">{"12:00PM - 12:00AM"}</p>
                    </div>
                    <div
                        className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-5 align-items-center border-left"
                        onClick={toogleMenu}
                    >
                        <p className="body-paragraph mb-0">{currentValue}</p>
                        <img
                            src="/images/icons/vendor-drop-icon.svg"
                            className={`${locMenu && "rotate-icon"}`}
                        />
                    </div>
                </div>
                <div
                    className={`loc-drop-down-container py-1 px-4 ${
                        locMenu ? "show" : "hide"
                    }`}
                >
                    <div className="drop-down-content">
                        {VendorStallsDates?.map((item) => {
                            return (
                                <p
                                    key={item.id}
                                    onClick={() => getCurrentDropdownData(item)}
                                    className="mb-0"
                                >
                                    {item.label}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    return (
      <div className="vendor-seats-wrapper">
        {FilteringDropdownVendors()}

        {loadingSlots ? (
          <Loader />
        ) : (
          <div className="vendors-all-seats">
            <div className="movie-container">
              <ul className="showcase">
                <li>
                  <div className="seat"></div>
                  <small>Regular Stall</small>
                </li>
                <li>
                  <div className="seat prime"></div>
                  <small>Prime Stall</small>
                </li>
                <li>
                  <div className="seat occupied"></div>
                  <small>Occupied Stall</small>
                </li>
              </ul>

              <div className="container">
                {renderSeats()}
                <p className="text">
                  You have selected{" "}
                  <span id="count">{selectedSeats.length}</span> stalls to book
                </p>
                <button className="genral-btn" onClick={BookSlots}>
                  Book Stalls
                </button>
              </div>
            </div>
          </div>
        )}

        {openLoginPopup.isOpen && (
            <LoginUserPopup
                fetchRecordsFromDates={openLoginPopup.fetchRecordsFromDates}
                allBookedSlots={openLoginPopup.allBookedSlots}
                regularSeats={openLoginPopup.regularSeats}
                primeSeats={openLoginPopup.primeSeats}
                onPopupClose={setOpenLoginPopup}
            />
        )}
      </div>
    );
};

export default VendorSeatsSlots; 