import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import EventTab from '../component/EventTab/EventTab'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import NewsList from "../component/NewsList/NewsList"
import Portfolio from "../component/Portfolio/Portfolio"
import Testimonial from "../component/Testimonials/Testimonials"
import { Helmet } from "react-helmet";

function home() {
  return (
    <> 
    <Helmet>
      <title>Uniting Communities: SIPRA Promotions Events</title>
      <meta name="description" content='Experience the joy of diverse celebrations with SIPRA Promotions. Join us for Matrimonial, Eid, Food Festivals & more. Celebrate life with us!'/>
      
      {/* <link rel="canonical" href=""></link> */}
    </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="It’s More Than Events, "
        nextLineSpanHeading="We Create Experiences"
        Paragraph="We Are Dedicated Towards Spreading Happiness Across Communities By Creating Once in A Lifetime Experiences. At SIPRA Promotions, Every Event is a Celebration of Life."
        bgImg="/images/creative/home-hero-img.webp"
        btn={false}
        btnText=""
        eventCountDown={true}
        eventCountHeading="Muslim Matrimonial"
        eventCountSpanHeading=" Starts In"
        targetDate={{ date: "25th", month: "August", year: "2024" }}
        textAlign="left"
        bgEffectOverlay={true}
      />
        

      <EventTab
        Heading=""
        spanHeading="OUR EVENTS"
        Paragraph="We organize events that go beyond the ordinary, providing a platform for individuals to come together, share their stories, and build lasting connections."
      />
      <Sponsor/>
      <Portfolio/>
      <TextContent
        heading="ABOUT"
        spanHeading=" SIPRA"
        content={"As the premier event organizing company for Pakistani-Canadian Muslims, we take immense pride in our mission to bring people together across Canada, transcending boundaries of race, gender, caste, and culture. At SIPRA, our mission is simple yet powerful. We strive to unite people from all walks of life, fostering a sense of belonging that goes beyond any differences. We believe in creating a community that celebrates diversity, embracing the richness that each individual brings to the tapestry of Canadian society. Our commitment to the community is resolute. We prioritize inclusivity, ensuring that our events welcome people from all backgrounds, fostering a sense of unity and understanding. We actively engage in initiatives that uplift and support less-advantaged Canadian Muslims, making a positive impact on their lives. From meticulous event planning to seamless execution, we bring passion and excellence to every aspect of our work."}
        btn={true}
        btnText="ABOUT US"
        btnRef="/about"
        shiftRow={true}
        img="/images/creative/about-text-content-img.webp"
        reviewContent= {false}
        mobileTextCenter={true}
      />

      <Testimonial/>

      <NewsList/>
    </>
  )
}

export default home