import React, { useState } from "react";
import "./VendorPackages.css";

const primeLocations = [
  {
    id: 1,
    days: "4 days",
    daysName: "",
    price: "$1100",
    anchor: "/",
  },
  {
    id: 2,
    days: "3 days",
    daysName: "",
    price: "$850",
    anchor: "/",
  },
  {
    id: 3,
    days: "2 days",
    daysName: "SAT AND SUN",
    price: "$600",
    anchor: "/",
  },
  {
    id: 4,
    days: "1 day",
    daysName: "",
    price: "$300",
    anchor: "/",
  },
];

const regularBooth = [
  {
    id: 5,
    days: "Each day",
    daysName: "",
    price: "$275",
    anchor: "/",
  },
  {
    id: 6,
    days: "Weekend",
    daysName: "",
    price: "$550",
    anchor: "/",
  },
  {
    id: 7,
    days: "3 days package",
    daysName: "",
    price: "$825",
    anchor: "/",
  },
  {
    id: 8,
    days: "4 days",
    daysName: "",
    price: "$1050",
    anchor: "/",
  },
];

const VendorLocationDropdown = [
  {
    id: 1,
    label: "Prime Location",
  },
  {
    id: 2,
    label: "Regular Booth",
  },
];

let timeoutId;
function VendorPackages({
  eventName = "",
  eventDate = "",
  eventLocation = "",
  eventTime = "",
}) {
  const [currentValue, setCurrentValue] = useState("Prime Location");
  const [currentVendorStall, setCurrentVendorStall] = useState(primeLocations);
  const [locMenu, setLocMenu] = useState(false);

  const toogleMenu = () => {
    setLocMenu((prev) => {
      return !prev;
    });
  };

  const getCurrentDropdownData = (currObj) => {
    if (currObj?.label == "Prime Location") {
      setCurrentVendorStall(primeLocations);
    } else {
      setCurrentVendorStall(regularBooth);
    }
    setCurrentValue(currObj?.label);
  };

  const fetchCheckoutSessionURL = async (id) => {
    try {
      const response = await fetch("/create-stripe-payment-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [
            {
              id: id,
              quantity: 1,
            },
          ],
        }),
      });
      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("session_id", data.session_id);
        const newTab = window.open(data.url, "_blank");
        if (newTab) newTab.focus();
        else window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error creating checkout session", error);
    }
  };

  const executeAPIForStripePaymentGateway = (obj) => {
    if (!obj.id) return;

    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fetchCheckoutSessionURL(obj.id);
    }, 300);
  };

  return (
    <div className="vendor-packages-container standard-padding-space mt-4">
      <div className="container">
        <div className="text-container text-center"> 

          {/* <div className="event-info-container my-5 ">
            <div className="row">
              <div className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-4 align-items-center border-right">
                <img src="/images/icons/location-icon.svg" />
                <p className="body-paragraph mb-0">{eventLocation}</p>
              </div>
              <div className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-4 align-items-center border-right">
                <img src="/images/icons/alarm-icon.svg" />
                <p className="body-paragraph mb-0">{eventTime}</p>
              </div>
              <div
                className="col-md-4 py-3 px-md-4 px-0 d-flex justify-content-center gap-5 align-items-center border-left"
                onClick={toogleMenu}
              >
                <p className="body-paragraph mb-0">{currentValue}</p>
                <img
                  src="/images/icons/vendor-drop-icon.svg"
                  className={`${locMenu && "rotate-icon"}`}
                />
              </div>
            </div>
            <div
              className={`loc-drop-down-container py-1 px-4 ${
                locMenu ? "show" : "hide"
              }`}
            >
              <div className="drop-down-content">
                {VendorLocationDropdown?.map((item) => {
                  return (
                    <p
                      key={item.id}
                      onClick={() => getCurrentDropdownData(item)}
                      className="mb-0"
                    >
                      {item.label}
                    </p>
                  );
                })}
              </div>
            </div>
          </div> */}

          {/* <div className="event-vendor-card-list">
            {currentVendorStall.lenght == 0 ? (
              <></>
            ) : (
              <div className="row gy-3">
                {currentVendorStall.map((ele, ind) => {
                  return (
                    <div className="col-lg-3">
                      <div className="booking-card-box d-flex flex-column align-items-center justify-content-center gap-4 py-4">
                        <h5
                          className={`box-days font-bebas ${
                            ind % 2 ? "color-blue" : "color-yellow"
                          }`}
                        >
                          {ele.days}
                        </h5>

                        <div>
                          <p
                            className={`body-paragraph mb-0`}
                            style={
                              ele.daysName == ""
                                ? { visibility: "none" }
                                : { visibility: "visible" }
                            }
                          >
                            {ele.daysName}
                          </p>
                          <h2
                            className="box-price"
                            style={
                              ele.daysName == ""
                                ? { marginTop: "24px", color: "white" }
                                : { color: "white" }
                            }
                          >
                            {ele.price}
                          </h2>
                        </div>

                        <a
                          className="box-anchor color-pink"
                          onClick={(evt) => {
                            evt.preventDefault();
                            executeAPIForStripePaymentGateway(ele);
                          }}
                        >
                          {" "}
                          BOOK NOW{" "}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default VendorPackages; 