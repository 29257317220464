import React from 'react'
import "./AboutText.css"

function AboutText() {
  return (
    <div className='about-text-container standard-padding-space'>
        <div className='container'>
            <div className='row pt-4 d-flex align-items-start'>
                <div className='col-lg-4 mx-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-1.svg' className='about-icon'/>
                    <h3 className='color-yellow m-0'>Who We Are</h3>
                    <p className='body-paragraph m-0'>SIPRA Promotions is the foremost event organizing company for Pakistani-Canadian Muslims. With a mission to unite communities across Canada, transcending barriers of race, gender, and culture, we are driven by ambition and compassion. Our commitment is to empower less-advantaged Canadian Muslims, ensuring they celebrate their religious festivals with boundless joy. Through inclusive and impactful events, we craft experiences that foster lasting connections, celebrating the diverse tapestry that makes up the fabric of Canadian society. Join SIPRA Promotions in creating moments that matter and building a community of unity and joy.</p>
                </div>
                <div className='col-lg-4 mx-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-2.svg' className='about-icon'/>
                    <h3 className='color-blue m-0'>Our Mission And Vision</h3>
                    <p className='body-paragraph m-0'>Our mission is to unite and uplift the Canadian Muslim community, transcending barriers of culture and socio-economic status. Through our commitment, we aim to create joyous, inclusive experiences that resonate with the rich array of our community. SIPRA Promotions envisions a vibrant and united Pakistani-Canadian Muslim community. We aspire to be the leading force in creating impactful, memorable celebrations that inspire unity, understanding, and pride in our shared heritage.</p>
                </div>
                <div className='col-lg-4 mx-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    <img src='/images/icons/text-3.svg' className='about-icon'/>
                    <h3 className='color-pink m-0'>What Did We Do</h3>
                    <p className='body-paragraph m-0'>We organize unforgettable experiences and events that celebrate the cultural richness of Pakistani-Canadian Muslims. From religious festivals to community gatherings, we meticulously plan and execute diverse occasions, fostering unity and inclusivity. Committed to making a positive impact, we actively engage in initiatives that uplift less-advantaged Canadian Muslims. Our dedication extends beyond event organization; we strive to create a lasting sense of community, joy, and connection that resonates long after the festivities conclude.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutText