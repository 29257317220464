import { useState } from "react"
import Modal from "../Modal/Modal";
import "./Portfolio.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Portfolio() {
    const [modal, setModal] = useState(false);
    const [imgSrc , setImgSrc] = useState("")

    const images = [
        "/images/creative/img-gallery-1.webp",
        "/images/creative/img-gallery-2.webp",
        "/images/creative/img-gallery-3.webp",
        "/images/creative/img-gallery-4.webp",
        "/images/creative/img-gallery-5.webp",
        "/images/creative/img-gallery-6.webp",
    ]

    const toggleModal = (e) => {
        setImgSrc(e.target.id)
        setModal((prev)=>{
            return !prev
        });
    };
    return (
        <div className="Portfolio-container standard-margin-space">
            <div className="container">
                {modal ? <Modal status={modal} showImg={imgSrc} allImages={images} closeModal={toggleModal} /> : <></>}
                    <h3 className="body-heading" >quick glance of our <span className="color-yellow">events</span></h3>   
                {/* <p className="portfolio-para body-paragraph"></p> */}
                <div className="images-gallery mx-auto d-md-block d-none">
                    <div className="row gy-4">

                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-1.webp" id="/images/creative/img-gallery-1.webp" onClick={toggleModal} className="img" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-2.webp" id="/images/creative/img-gallery-2.webp" onClick={toggleModal} alt="img0" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-3.webp" id="/images/creative/img-gallery-3.webp" onClick={toggleModal} alt="img1" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-4.webp" id="/images/creative/img-gallery-4.webp" onClick={toggleModal} alt="img2" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-5.webp" id="/images/creative/img-gallery-5.webp" onClick={toggleModal} alt="img3" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12">
                            <img src="/images/creative/img-gallery-6.webp" id="/images/creative/img-gallery-6.webp" onClick={toggleModal} alt="img4" />
                        </div>
                    </div>
                </div>
                <div className="py-4 d-md-none d-block">

                <Swiper
                    spaceBetween={50}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                      }}
                    loop={true}
                    modules={[Autoplay]}
                    className="py-3 mx-auto"
                    >
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-1.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-1.webp" onClick={toggleModal}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-2.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-2.webp" onClick={toggleModal} alt="img1" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-3.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-3.webp" onClick={toggleModal} alt="img2" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-4.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-4.webp" onClick={toggleModal} alt="img3" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-5.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-5.webp" onClick={toggleModal} alt="img4" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/images/creative/img-gallery-6.webp" className="img-fluid swiper-images" id="/images/creative/img-gallery-6.webp" onClick={toggleModal} alt="img4" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                
            </div>
        </div>
    )
}

export default Portfolio