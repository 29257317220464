import React from 'react'

function PopularBlogCard({
    img,
    title,
    date,
    anchor,
    redirectionFunction
}) {
  return (
    <div className='popular-card d-flex gap-2 align-items-center my-5 ' onClick={()=>{redirectionFunction(anchor)}}>
        <img src={img} className='popular-blog-img mx-2'/>
        <div className='blog-details'>
            <h5 className='card-title font-bebas'>{title}</h5>
            <p className='card-date'>{date}</p>
        </div>
    </div>
  )
}

export default PopularBlogCard