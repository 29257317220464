import React from 'react'
import "./VendorFloorPlan.css"
import VendorSeatsSlots from './VendorSeatsSlots'

function VendorFloorPlan() {
  return (
    <div className='floor-plan-container standard-padding-space mt-4'>
        <div className='container'>
            <div className='text-container text-center'>
                <h3 className='body-heading color-yellow'>FLOOR PLAN EID FESTIVAL 2023</h3>
                <h5 className='title font-bebas'>BOOTH SIZE 10 X 8</h5> 
            </div>

            <div className='floor-plan mt-5'>
              <VendorSeatsSlots /> 
            </div>
        </div>
    </div>
  )
}

export default VendorFloorPlan