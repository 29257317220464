import React, { useState,useEffect } from "react";
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Testimonials from '../component/Testimonials/Testimonials'
import EventBox from '../component/EventBox/EventBox'
import Sponsor from '../component/Sponsor/Sponsor'
import EventCountDown from '../component/EventCountDown/EventCountDown'
import UpcomingEventBox from '../component/UpcomingEvent/EventBox'
import { Helmet } from "react-helmet";


function getTimeLeft(targetDate) {
  const months = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const targetMonth = months.indexOf(targetDate.month.toUpperCase());

  const targetTime = new Date(targetDate.year, targetMonth, parseInt(targetDate.date), 0, 0, 0, 0).getTime();
  const currentTime = new Date().getTime();

  if (targetTime < currentTime) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    };
  }

  let timeDiff = targetTime - currentTime;

  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  const padWithZero = (value) => (value < 10 ? `0${value}` : `${value}`);

  return {
    days: padWithZero(days),
    hours: padWithZero(hours),
    minutes: padWithZero(minutes),
    seconds: padWithZero(seconds)
  };
}






function EventInner({
  heroImg="",
  heroHeading="",
  heroHeadingSpan="",
  heroSubHeading="",
  heroDate={},
  heroDateContainer=false,
  eventInfo={},
  countDownTime={days:"00",hours : "00",minutes:"00",seconds:"00"},
  ticketBookingSection=false,
  targetDate={ date: "0th", month: "AUGUST", year: "2020" },
  ticketData,
  meta
}) {

  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTimeLeft = getTimeLeft(targetDate);
      setTimeLeft({days:`${updatedTimeLeft.days}`,hours : `${updatedTimeLeft.hours}`,minutes:`${updatedTimeLeft.minutes}`,seconds:`${updatedTimeLeft.seconds}`});

      if (updatedTimeLeft.days === '00' && updatedTimeLeft.hours === '00' && updatedTimeLeft.minutes === '00' && updatedTimeLeft.seconds === '00') {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  console.log("data",ticketData);
  return (
    <>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.desc}/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
        <HeroBanner 
        nextLineSpanHeading=""
        Heading={heroHeading}
        spanHeading={heroHeadingSpan}
        subHeading={heroSubHeading}
        Paragraph=""
        btnText=""
        bgImg={heroImg}
        btn={false}
        dateContainer={heroDateContainer}
        date={heroDate}
        />

        <EventCountDown
            countDown={targetDate===null ? countDownTime : timeLeft}
        />
      {ticketBookingSection && 
      <div className="container up-event-container my-4">
      
      {ticketData.map((ele)=>{
        return <UpcomingEventBox
        borderColor={ele.borderColor}
        btnText={ele.btnText}
        btnColor={ele.btnColor}
        date={ele.date}
        eventName={ele.eventName}
        eventText={ele.eventText}
        eventLocation={ele.eventLocation}
        eventTime={ele.eventTime}
        btnLink={ele.btnLink}
      />
      })}
        </div>
              }
        <EventBox 
          reverse={eventInfo.reverse}
          img={eventInfo.img}
          subHeading={eventInfo.subHeading}
          title={eventInfo.title}
          content={eventInfo.content}
          btnRoute={eventInfo.btnRoute}
          borderColor={eventInfo.borderColor}
          listPoint={eventInfo.listPoint}
          btnText='CONTACT US'
        />

        <Sponsor/>
        
        <Testimonials/>
    </>
  )
}

export default EventInner