import React,{useState,useEffect} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import EventCountDown from "../EventCountDown/EventCountDown";
import { useNavigate } from "react-router-dom";

function getTimeLeft(targetDate,isContainer) {
  const months = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  if(isContainer)
  {
    const targetMonth = months.indexOf(targetDate.month.toUpperCase());
  
    const targetTime = new Date(targetDate.year, targetMonth, parseInt(targetDate.date), 0, 0, 0, 0).getTime();
    const currentTime = new Date().getTime();
  
    if (targetTime < currentTime) {
      return {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      };
    }
  
    let timeDiff = targetTime - currentTime;
  
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
    const padWithZero = (value) => (value < 10 ? `0${value}` : `${value}`);
  
    return {
      days: padWithZero(days),
      hours: padWithZero(hours),
      minutes: padWithZero(minutes),
      seconds: padWithZero(seconds)
    };

  }
}

const HeroBanner = ({
  Heading,
  subHeading,
  spanHeading="",
  nextLineSpanHeading="",
  Paragraph,
  btnText,
  bgImg,
  btn=false,
  textAlign="center",
  socialLinks=false,
  serviceForm=false,
  dateContainer=false,
  date,
  bgEffect=true,
  bgEffectOverlay=false,
  eventCountDown=false,
  eventCountHeading="",
  eventCountSpanHeading="",
  targetDate=null
}) => {
  
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate,eventCountDown));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Contact Main Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };

    useEffect(() => {
      const intervalId = setInterval(() => {
        if(eventCountDown)
        {
          const updatedTimeLeft = getTimeLeft(targetDate,eventCountDown);
          setTimeLeft({days:`${updatedTimeLeft.days}`,hours : `${updatedTimeLeft.hours}`,minutes:`${updatedTimeLeft.minutes}`,seconds:`${updatedTimeLeft.seconds}`});
    
          if (updatedTimeLeft.days === '00' && updatedTimeLeft.hours === '00' && updatedTimeLeft.minutes === '00' && updatedTimeLeft.seconds === '00') {
            clearInterval(intervalId);
          }
        }
        else{
          clearInterval(intervalId)
        }
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [targetDate]);

  return (
    <div
      className="common_hero_section bg-info"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      {bgEffectOverlay && <div className="bg-effect-overlay"></div>}
      {bgEffect && <div className="bg-effect"></div>}
      <Header />
      <div className="container d-flex flex-column pt-5" style={{height:"100%"}}>
      <div className={`row my-auto gy-3 ${(serviceForm || eventCountDown) && "align-items-center"}`}>
        <div className={`${serviceForm || eventCountDown ? "col-md-6 px-md-0 px-4": "col-12 hero_content_wrapper"} mx-auto gap-4 d-flex flex-column justify-content-center ${textAlign === 'center' ? "align-items-center":"align-items-start"}`}>
            <div className={`text-content d-flex flex-column justify-content-center ${textAlign === 'center' ? "align-items-center text-center":"align-items-start text-left "}`}>
              <h1 className="hero-section-heading">
                {Heading}<span className="color-pink">{spanHeading}</span>
              </h1>
              {subHeading=="" ?<></> :<h5 className="sub-heading" >{subHeading}</h5>}
              <h1 className="hero-section-heading"><span className="color-pink">{nextLineSpanHeading}</span></h1>
              <p className={`body-paragraph ${textAlign === "center" && "w-75"}`}>{Paragraph}</p>
            {dateContainer && <div className="date-container d-flex flex-column flex-sm-row gap-3"> <h5 className="sub-heading m-0">{date.date}</h5><h5 className="sub-heading m-0">{date.month}</h5><h5 className="sub-heading m-0">{date.year}</h5></div>}
            </div>
            {btn && <button className="genral-btn d-flex align-items-center justify-content-center gap-2">{btnText} <img src="/images/icons/play-icon.svg"/></button>}
            {socialLinks && (
              <div className="social-links py-2 ">
                <div className="first-row d-flex flex-md-row flex-column gap-3">
                  <div className="link-container d-flex align-items-center gap-2">
                    <img src="/images/icons/social-phone.svg" />
                    <p className="m-0">+1 (416) 577-0055</p>
                  </div>
                  <div className="link-container d-flex align-items-center gap-2">
                    <img src="/images/icons/social-mail.svg" />
                    <p className="m-0">info@siprapromotions.com</p>
                  </div>
                </div>
                <div className="link-container d-flex align-items-center gap-2 mt-3">
                  <img src="/images/icons/social-location.svg" />
                  <p className="m-0">Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4</p>
                </div>
              </div>
            )}
        </div>
        {serviceForm && (
            <div className="col-md-5 mx-auto pb-5 px-md-0 px-4">
              <form className="service-form d-flex flex-column px-5 py-5" onSubmit={formSubmitHandler}>
                <h3 className="body-heading" style={{ color: "white",fontSize:"2rem" }}>
                  Let’s get in <span className="color-yellow">touch!</span>
                </h3>
                <p
                  className="body-paragraph text-start"
                  style={{ color: "white" }}
                >
                  Feel free to reach out using the contact form below.
                </p>
                <input 
                  type="text" 
                  value={name}
                  maxLength="36"
                  onChange={handleNameChange}
                  placeholder="Your Full Name" 
                  />
                <input 
                  type="email" 
                  value={email}
                  maxLength="66"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                  />
                <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" />
                <input 
                  type="text" 
                  value={message}
                  maxLength="150"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" />
                <button className="service-from-btn" type="submit">
                  Submit
                  {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                  </button>
              </form>
            </div>
          )}
        {eventCountDown && (
            <div className="col-md-5 mx-auto pb-5 px-md-0 px-4">
              <div className="service-form d-flex flex-column text-center px-2 py-5">
                <h3 className="body-heading" style={{ color: "white", }}>
                  {eventCountHeading} <span className="color-pink">{eventCountSpanHeading}</span>
                </h3>
                <EventCountDown
                    countDown={timeLeft}
                    showHeading={false}
                />
                <button className="service-from-btn mx-auto" onClick={()=>{navigate("/event?id=bookticket")}}>Get Tickects</button>
              </div>
            </div>
          )}
          </div>
    </div>
    </div>
  );
};

export default HeroBanner; 