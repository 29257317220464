import React, { useState } from 'react';
import './LoginUserPopup.css'; 
import { getLoggedInUserDiscounts, showPopupMessage } from '../Settings/Helper';  

let timeoutId;
let timeoutIdWithoutLogin;

const LoginUserPopup = ({onPopupClose, regularSeats, primeSeats, allBookedSlots, fetchRecordsFromDates}) => {
    const [userLogin, setUserLogin] = useState({
        email: null,
        password: null,
        username: null
    }); 

    const executeAPICallForLoginUser = async () => {
        try {
            const response = await fetch("/login-user", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    password: userLogin.password,
                    username: userLogin.username,
                    email: userLogin.email
                }),
            }); 
            
            if (response.ok) {
                executeAPIForStripePaymentGatewayPrime();
                executeAPIForStripePaymentGatewayRegular(); 
                showPopupMessage('Login Successfull!', 'success');
            }else {
                showPopupMessage('Please provide valid login credentials!'); 
            }
        } catch (error) {
            showPopupMessage('Apologies, something went wrong from our side!'); 
        }
    };

    const fetchCheckoutSessionURL = async (id, slots_booked, price, description) => {
        try {
            const response = await fetch("/create-stripe-payment-checkout-session", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    items: [
                        {
                            id: id,
                            quantity: slots_booked.length,
                            price: price ? price : null,
                            description: description ? description : null
                        }
                    ]
                }),
            });
            const data = await response.json();

            if (response.ok) {
                localStorage.setItem("session_id", data.session_id);
                localStorage.setItem("all_slots", slots_booked);
                localStorage.setItem("date", fetchRecordsFromDates);
                localStorage.setItem("type", id == 1 ? 'Prime' : 'Regular');
                
                const newTab = window.open(data.url, "_blank");
                if (newTab) newTab.focus();
                else window.location.href = data.url;
                showPopupMessage('Redirecting you to our secure payment gateway!', 'success');
            }
        } catch (error) {
            showPopupMessage('Apologies, something went wrong from our side!');
        }
    };

    const executeAPIForStripePaymentGatewayPrime = () => {
        if (primeSeats.length === 0) return;
 
        const userSlots = getLoggedInUserDiscounts("Prime", userLogin?.username, userLogin?.email, allBookedSlots, fetchRecordsFromDates);
        if(userSlots === 2) { 
            fetchCheckoutSessionURL(1, primeSeats, 25000, '*50$ Discount*');
            return;
        };
        if(userSlots === 3) {
            fetchCheckoutSessionURL(1, primeSeats, 20000, '*100$ Discount*');
            return;
        };

        fetchCheckoutSessionURL(1, primeSeats);
    };

    const executeAPIForStripePaymentGatewayRegular = () => {
        if (regularSeats.length === 0) return;
 
        const userSlots = getLoggedInUserDiscounts("Regular", userLogin?.username, userLogin?.email, allBookedSlots, fetchRecordsFromDates);
        if(userSlots === 3) { 
            fetchCheckoutSessionURL(2, regularSeats, 22500, '*50$ Discount*');
            return;
        };

        fetchCheckoutSessionURL(2, regularSeats);
    };

    const onFieldInputValueChange = (e) => {
        const { name, value } = e.target;
        
        setUserLogin((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const PopupCloseHandler = () => { 
        onPopupClose((prevState) => {
            return {
                ...prevState,
                isOpen: false,
                regularSeats: [],
                primeSeats: []
            }
        }); 
    };

    const onLoginUser = (e) => {
        e.preventDefault();
         
        if(!userLogin.email || !userLogin.username || !userLogin.password){
            showPopupMessage('Please provide all required fields, username, email, & password!');
            return;
        };
  
        if (timeoutId) clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            executeAPICallForLoginUser(); 
        }, 200); 
 
        PopupCloseHandler();
    };

    const onContinueWithoutLogin = (e) => {
        e.preventDefault();
          
        if(timeoutIdWithoutLogin) clearTimeout(timeoutIdWithoutLogin);

        timeoutIdWithoutLogin = setTimeout(() => {
            executeAPIForStripePaymentGatewayPrime();
            executeAPIForStripePaymentGatewayRegular();
        }, 200);  
 
        PopupCloseHandler();
    };
 
    return (
        <div className='login-user-wrapper'>  
            <div className="popup">  
                <div className="popup-inner"> 
                    <h2>Booked a Stall Before?</h2>
                    <p>If you have previously made a reservation for any of our stalls, we had sent you the login credentials via email.<br/> We kindly request you to log in to your account to take advantage of exclusive discounts available to you.</p>
                    
                    <div className="form-group">
                        <label>Username<span className='red-asterik'>*</span></label>
                        <input type="text" onChange={onFieldInputValueChange} value={userLogin.username} id="username" name="username" />
                    </div>
                    <div className="form-group">
                        <label>Email<span className='red-asterik'>*</span></label>
                        <input type="email" onChange={onFieldInputValueChange} value={userLogin.email} id="email" name="email" />
                    </div>
                    <div className="form-group">
                        <label>Password<span className='red-asterik'>*</span></label>
                        <input type="password" onChange={onFieldInputValueChange} value={userLogin.password} id="password" name="password" />
                    </div> 
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-6 d-flex justify-content-start'>
                            <div> <button className='genral-btn' onClick={onLoginUser}>Login</button> </div>
                            <div className='ms-2'> <button className='genral-btn' onClick={onContinueWithoutLogin}>Continue Without Login</button> </div>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-end '>
                            <button className='genral-btn' onClick={PopupCloseHandler}>Close</button> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    );
}

export default LoginUserPopup;