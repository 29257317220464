import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import AboutText from '../component/AboutText/AboutText'
import Sponsor from '../component/Sponsor/Sponsor'
import Testimonials from '../component/Testimonials/Testimonials'
import { Helmet } from "react-helmet";

function about() {
  return (
    <>
        <Helmet>
          <title>SIPRA Promotions: Uniting Pakistani-Canadian Muslims</title>
          <meta name="description" content='Join SIPRA Promotions, the premier event organizing company for Pakistani-Canadian Muslims, in creating joyous and empowering experiences across Canada.'/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
        <HeroBanner 
            subHeading=""
            Heading="ABOUT "
            spanHeading="US"
            Paragraph="We are a premier event organizing company for Pakistani-Canadian Muslims on a mission to unite people across Canada. Our events are designed to entertain and empower."
            btnText=""
            bgImg="/images/creative/about-hero-img.webp"
            btn={false}
            bgEffectOverlay={true}
        />
        <AboutText/>
        <Sponsor/>
        <Testimonials/>
    </>
  )
}

export default about