import React from 'react'

function EventBox({
    borderColor,
    btnText,
    btnColor,
    date={day:"",month:""},
    eventName="",
    eventText="",
    eventLocation="",
    eventTime="",
    btnLink="#"
}) {
  return (
    <div className={`event-box-container border-${borderColor} d-flex flex-xl-row flex-column align-items-center justify-content-between px-4 gap-3 mt-5`}>
        <div className='upper-section d-flex flex-md-row flex-column align-items-center justify-content-center gap-3 p-3 py-5'>
            <div className='date-container'>
                <h2 className='m-0'>{date.day}</h2>
                <h4 className='mb-0'>{date.month}</h4>
            </div>
            {/* <img src='/images/creative/event-img.jpg' className='upper-event-img' /> */}
            <div className='event-details text-center text-md-start'>
                <h6>{eventName}</h6>
                <p className='mb-0'>{eventText}</p>
            </div>

        </div>

        <div className='middle-section d-flex flex-md-row flex-column mx-2 gap-3'>
            <div className='icon-list d-flex align-items-center gap-3'>
                <img src='/images/icons/location.svg' />
                <p className='mb-0'>{eventLocation}</p>
            </div>
            <div className='icon-list d-flex align-items-center gap-3'>
                <img src='/images/icons/alarm.svg' />
                <p className='mb-0'>{eventTime}</p>
            </div>
        </div>
        {
            btnLink == "#"
            ?
                <button className={`genral-btn-plain my-xl-0 my-4 btn-${btnColor} `}>{btnText}</button>
            :
            <a
                href={btnLink}
                target='blank'
                style={{textDecoration:"none"}}
            >
                <button className={`genral-btn-plain my-xl-0 my-4 btn-${btnColor} `}>{btnText}</button>
            </a>
        }
        
    </div>
  )
}

export default EventBox