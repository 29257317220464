import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import PopularBlog from "../component/PopularBlogs/PopularBlogs"
import { Helmet } from "react-helmet";

function blog() {
  return (
    <>
    <Helmet>
          <title>Latest News & Popular Updates</title>
          <meta name="description" content='Book stalls for the Floor Plan Eid Festival 2023 at The Estate Banquet Hall, Scarborough. Choose from regular, prime, or occupied stalls.'/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
        <HeroBanner 
            subHeading=""
            Heading="LATEST "
            spanHeading="NEWS"
            Paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
            btnText=""
            bgImg="/images/creative/blog-hero-bg.jpg"
            btn={false}
        />
        <PopularBlog/>
    </>
  )
}

export default blog