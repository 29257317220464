import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserCheckout = () => {
    const [showBar, setShowBar] = useState(false);
    const navigate = useNavigate();

    const sessionId = localStorage.getItem('session_id');
    const allSlots = localStorage.getItem('all_slots');
    const slotType = localStorage.getItem('type');
    const eventDate = localStorage.getItem('date');

    const emailConfirmationMessage = { 
        subject: '*Payment Received & Slots Booked*', 
        message:
        `
        The following message is to confirm you, that we have received your payment successfully, and your selected slots were booked accordingly. 
        
        Thank you for your patience!
        `
    };

    useEffect(() => { getClientCreds() }, []);

    const getClientCreds = async () => {
        try { 
            if (!sessionId) {
                setShowBar(false);
                setTimeout(() => { navigate('/home') }, 3000);
                return;
            }
            const response = await fetch(`/get-session-details?session_id=${sessionId}`);
            const data = await response.json();
    
            if (response.ok) { 
                executeAPIForBookedSlots(data?.customer_email, data?.customer_name)
                setShowBar(true);
            }
        } catch (error) { 
            setShowBar(false);
            setTimeout(() => { navigate('/home') }, 3000);

            localStorage.removeItem('session_id');
            localStorage.removeItem('all_slots');
            localStorage.removeItem('type');
            localStorage.removeItem('date');
        }
    };

    const executeAPIForBookedSlots = async (customer_email, customer_name) => {
        try {
            const response = await fetch('/book-slot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    no_slots: allSlots,
                    date: eventDate,
                    type: slotType,
                    username: customer_name,
                    email: customer_email,
                })
            });
            const data = await response.json(); 
            if (response.ok) executeConfirmationMessageAPI(customer_email);  
        } catch (error) {
            console.error('Error executing email to client!', error);
        } 
    };

    const executeConfirmationMessageAPI = async (customer_email) => {
        try {
            const response = await fetch('/trigger-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...emailConfirmationMessage,
                    to: customer_email
                })
            });
            const data = await response.json(); 
            if (response.ok) { 
                localStorage.removeItem('session_id');
                localStorage.removeItem('all_slots');
                localStorage.removeItem('type');
                localStorage.removeItem('date');
            }
        } catch (error) {
            console.error('Error executing email to client!', error);
        } 
    };

    const success_message_text = `You have successfully made the payment, our administrator will soon contact you through your email, navigating you to our website...`;
    const fail_message_text = `You didnt made any payment, navigating you to our website...`;

    const userCheckoutModal = () => {
        return (
            <div className='user_success_checkout'>
                <div className={`${showBar ? 'success_message' : 'fail_message'}`}>{showBar ? success_message_text : fail_message_text}</div>
            </div>
        )
    };

    return ( 
    <Fragment> {userCheckoutModal()} </Fragment> 
    );
};

export default UserCheckout;