import React,{useEffect,useState} from "react";
import "./NewsList.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import axios from "axios";
import { useNavigate } from "react-router-dom";

let defaultBlogObj

function CardTestimonial() {

  const blogData = [
    {cardImg:"/images/creative/blog-1.jpg",title:"Eid Extravaganza: A Guide to Celebrating Eid-ul-Fitr in Canada", content:"Discover how SIPRA Promotions is dedicated to making this special occasion memorable for all, fostering unity and inclusivity." },
    {cardImg:"/images/creative/blog-2.jpg",title:"The Spirit of Ramadan: SIPRA Promotions' Initiatives for a Meaningful Holy Month", content:"Learn about SIPRA Promotions' efforts to make this sacred month even more impactful for the Muslim community in Canada." },
    {cardImg:"/images/creative/blog-3.jpg",title:"SIPRA Promotions' Journey: A Reflection on Milestones in Muslim Event Organizing", content:"Take a trip down memory lane as SIPRA Promotions reflects on its journey, highlighting key milestones in organizing Muslim events." },
  ]

  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blog/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
          
      if (blogs.length <= 3) {
        setBlogs([...blogs, ...blogs]);
      }
        }
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  return (
    <div className='CardTestimonial-container standard-padding-space'>
      <div className="container">
        <div className="testimonial-text-content text-center">
            <h3 className="body-heading">News<span className="color-blue"> & </span>Blogs</h3>   
            <p className="body-paragraph w-75 mx-auto">Welcome to our blog & news section, your go-to destination for insightful articles, event inspiration, and community stories. Join us on a journey of discovery, as we share tips, trends, and heartwarming tales that reflect the essence of our events. It's all about celebrations, community building, and the power of inclusivity.</p>
        </div>

        <Swiper
            spaceBetween={25}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                610:{
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
            className="card-list"
            >
            {blogs.map((ele,ind)=>{
              return (<>
                <SwiperSlide key={ind}>
                  <CardBox 
                    cardImg={ele.blog_creative} 
                    title={ele.main_heading} 
                    content={ele.paragraph} 
                    anchor={ele.slug_url}
                    redirectionFunction={redirectUserToBlog}
                  />
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>
        </div>
    </div>
  )
}

export default CardTestimonial