import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import { Helmet } from "react-helmet";


function Contact() {



  return (
    <>
    <Helmet>
          <title>Contact Us for Event Registration or Sponsorship | Sipra Promotions</title>
          <meta name="description" content='Reach out to Sipra Promotions for event registration, sponsorship, inquiries, or feedback. Contact us at +1 (416) 577-0055 or info@siprapromotions.com.'/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
    <HeroBanner 
      subHeading=""
      Heading="CONTACT "
      spanHeading='US'
      nextLineSpanHeading=""
      Paragraph="If you have inquiries, feedback, or would like to register for or sponsor an event, we are here to connect. Contact us right away."
      btnText=""
      bgImg="/images/creative/contact-hero-img.jpg"
      btn={false}
      textAlign="left"
      socialLinks={true}
      serviceForm={true}
    />

  </>
  )
}

export default Contact